import React, { useState, useEffect } from "react";
import { navigate, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Container, Row, Card, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { User, Phone, Mail } from "react-feather";
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";
import Axios from "axios";
import SEO from "@components/SEO";
import { getURLParam } from "@helper";
import PhoneIcon from "@assets/images/icon/phone.svg";

import css from "@assets/styles/scss/modules/form.module.scss";

const BackupForm = () => {
  const img = useStaticQuery(graphql`
    query BackupFormQuery {
      imgFormDesktop: file(
        relativePath: { eq: "pages/desktop/hubungi-kami/form-side-image.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 680, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgFormMobile: file(
        relativePath: { eq: "pages/mobile/hubungi-kami/form-side-image.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 255, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const imgForm = [
    img.imgFormDesktop.childImageSharp.fluid,
    {
      ...img.imgFormMobile.childImageSharp.fluid,
      media: "(max-width: 1023.98px)",
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [device, setDevice] = useState("");
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.body.style.background =
        "linear-gradient(#89c6bd, #a5d3c9, #d3dccf, #487275) no-repeat center center fixed";
      document.body.style.backgroundSize = "cover";
    }

    if (isMobile) {
      setDevice("mobile");
    } else {
      setDevice("desktop");
    }

    return () => {
      document.body.style.background = "";
      document.body.style.backgroundSize = "";
    };
  }, []);

  const onSubmit = data => {
    const payload = {
      action: "insert",
      no: "=ROW()-1",
      tanggal: dayjs().format("DD MMMM YYYY HH:mm:ss A"),
      nama: data.nama,
      no_hp: data.no_hp,
      email: data.email,
      source: device,
      keyword: getURLParam("utm_term"),
      campaign_id: getURLParam("utm_campaign"),
    };

    setIsLoading(true);

    Axios.get(
      "https://script.google.com/macros/s/AKfycbwUM1inMU3J_5CsvjdmnV05jaS-Zv4NgDS_M7cQ2-XVZ-MgKV7Kj5Ye/exec",
      {
        params: payload,
      }
    )
      .then(() => {
        setIsLoading(false);
        navigate("/contact/");
      })
      .catch(error => {
        console.error("Error : ", error);
        setIsLoading(false);
        alert("Terjadi kesalahan. Silahkan coba beberapa saat lagi.");
      });
  };

  return (
    <div className={css.form}>
      <SEO title="Form" />
      <Row className={css.formCard}>
        <Col className={css.formContent}>
          <div className={css.formWrapper}>
            <div className={css.formHeader}>
              <img src={PhoneIcon} className={css.phoneIcon} />
              <h6 className={css.title1}>
                Untuk kemudahan berkomunikasi, silakan isi form di bawah ini:
              </h6>

              <h6 className={css.title2}>
                Setelah mengisi form, Anda akan menuju halaman informasi alamat
                dan nomor telepon Gudang Seragam
              </h6>
            </div>
            <Form
              className={css.formInput}
              onSubmit={!isLoading ? handleSubmit(onSubmit) : null}
            >
              <Form.Group className="w-100">
                <div className={css.inputIconWrapper}>
                  <User color="gray" className="mr-2" />
                  <Form.Control
                    plaintext
                    name="nama"
                    placeholder="Nama"
                    isInvalid={errors.nama}
                    className={css.input}
                    ref={register({
                      minLength: {
                        value: 2,
                        message: "Nama tidak boleh kurang dari 2 karakter.",
                      },
                      required: "Nama tidak boleh kosong.",
                    })}
                  />
                </div>
                <Form.Text className="text-danger">
                  {errors.nama && errors.nama.message}
                </Form.Text>
              </Form.Group>

              <Form.Group className="w-100">
                <div className={css.inputIconWrapper}>
                  <Phone color="gray" className="mr-2" />
                  <Form.Control
                    plaintext
                    name="no_hp"
                    type="number"
                    placeholder="No. Hp / Whatsapp"
                    isInvalid={errors.no_hp}
                    className={css.input}
                    ref={register({
                      minLength: {
                        value: 10,
                        message: "No. HP tidak boleh kurang dari 10 karakter.",
                      },
                      required: "No. HP tidak boleh kosong.",
                    })}
                  />
                </div>
                <Form.Text className="text-danger">
                  {errors.no_hp && errors.no_hp.message}
                </Form.Text>
              </Form.Group>

              <Form.Group className="w-100">
                <div className={css.inputIconWrapper}>
                  <Mail color="gray" className="mr-2" />
                  <Form.Control
                    plaintext
                    name="email"
                    type="email"
                    placeholder="E-mail"
                    isInvalid={errors.email}
                    className={css.input}
                    ref={register({
                      required: "Email tidak boleh kosong",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Mohon masukkan alamat email yang valid.",
                      },
                    })}
                  />
                </div>
                <Form.Text className="text-danger">
                  {errors.email && errors.email.message}
                </Form.Text>
              </Form.Group>

              <Button
                type="submit"
                disabled={isLoading}
                className={css.btnSubmit}
              >
                <h6 className="mb-0">
                  {isLoading ? "MENGIRIM . . . " : "KIRIM"}
                </h6>
              </Button>
            </Form>
          </div>
          <div className={css.formImg}>
            <Img
              fluid={imgForm}
              className="h-100"
              imgStyle={{
                objectPosition: "0 0",
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BackupForm;
